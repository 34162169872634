<template>
  <ContentSection class="bg-amber-100">
    <Container indent="container-tight">
      <AnimatedContainer trigger-start="top 80%">
        <!-- TITLE -->
        <h2
          v-if="data.heading"
          class="mb-7 max-w-[75%] text-base font-semibold sm:mb-8 sm:text-lg lg:max-w-title"
        >
          {{ data.heading }}
        </h2>
        <!-- GRID -->
        <ul class="grid grid-cols-1 gap-x-6 gap-y-3 sm:gap-y-8">
          <!-- CARD -->
          <li v-for="(file, index) in data.files" :key="index" class="fade-in">
            <Component
              :is="checkIfFile(file) ? 'button' : 'a'"
              v-if="file.file || file.link_to_file"
              :aria-label="`Datei herunterladen: ${file.name}`"
              class="hover:bg-cream focus-default group flex h-full min-h-[2rem] w-full cursor-pointer items-center justify-between rounded-md border-3 border-transparent bg-white px-[0.875rem] py-5 transition-all hover:rounded-2xl hover:border-blue-500 hover:green:border-green-500 hover:pink:border-pink-500 sm:py-6 sm:px-8"
              :href="file.link_to_file"
              v-bind="
                file.open_in_tab ? { target: '_blank', rel: 'noopener' } : null
              "
              @click="
                checkIfFile(file) && !file.link_to_file
                  ? downloadFile(
                      file.file.permalink,
                      file.name,
                      file.open_in_tab
                    )
                  : null
              "
            >
              <div class="flex items-center">
                <IconDownload
                  class="mr-6 h-6 w-6 shrink-0 blue:text-blue-500 green:text-green-500 pink:text-pink-500 sm:mr-12 sm:h-8 sm:w-8"
                />

                <div class="text-left text-base leading-[1.625rem] sm:text-2xl">
                  <!-- FILE TITLE -->
                  <span class="hyphens-auto xl:hyphens-none pr-2 sm:pr-4">{{
                    file.name
                  }}</span>

                  <!-- FILE FORMAT -->
                  <!-- show when it's a uploaded file -->
                  <span class="inline-block whitespace-nowrap">
                    <span
                      v-if="file.file"
                      class="text-left text-3xs uppercase leading-tight opacity-60 sm:text-2xs"
                    >
                      <!-- eslint-disable-next-line -->
                    <span class="sm:mr-2 mr-1 font-bold">{{ file.file.extension }}</span>
                      <span>({{ file.file.size }})</span>
                    </span>

                    <!-- show when it's a link to a file -->
                    <span
                      v-if="file.file_type || file.file_size"
                      class="pl-2 text-left text-3xs uppercase leading-tight opacity-60 sm:pl-4 sm:text-2xs"
                    >
                      <!-- eslint-disable-next-line -->
                    <span class="sm:mr-2 mr-1 font-bold">{{ file.file_type }}</span>
                      <span>({{ file.file_size }})</span>
                    </span>
                  </span>
                </div>
              </div>
            </Component>
          </li>
        </ul>
        <div v-if="data.link" class="mt-9 flex w-full justify-end sm:mt-10">
          <AppButton :to="data.link" aria-label="Zeige alle Downloads an">
            {{ data.button_text }}
          </AppButton>
        </div>
      </AnimatedContainer>
    </Container>

    <!-- DECORATION -->
    <div
      v-if="Array.isArray(data.files) && data.files.length > 2"
      class="absolute top-[-4%] right-0 w-[18%] min-w-[7rem] overflow-hidden md:w-[15%]"
    >
      <IconBlueString
        class="translate-x-[15%] text-blue-500 green:text-green-500 pink:text-pink-500"
      />
    </div>
  </ContentSection>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    // Files won't download directly when they are from another domain (security).
    // This function fetches it first and creates temporary local file which can then be downloaded direct.
    async downloadFile(file, name, openInTab) {
      const response = await fetch(file)
      // Content to blob address
      const blob = await response.blob()
      // Create hidden downloadable links
      const objectUrl = window.URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.href = objectUrl

      if (openInTab) {
        // Safari is blocking any call to window.open() which is made inside an async call.
        // setTimeout code runs on the main thread, instead of the asynchronous one
        setTimeout(() => {
          window.open(objectUrl, '_blank')
        })
        return
      }

      a.download = name
      document.body.appendChild(a)

      a.click()

      // remove
      setTimeout(() => document.body.removeChild(a), 1000)
    },

    checkIfFile(file) {
      return file.file
    },
  },
}
</script>
